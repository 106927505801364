<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="container mt-5">
        <BookingInfo style="display:none;" />
        <div class="row">
          <section class="col-lg-8 col-md-7 col-sm-6 modify-form">
            <h3>What do you want to change?</h3>
            <hr>
            <div class="row">
              <div class="col-lg-9">
                <form>
                  <div class="form-group has-icon">
                    <label>Dates:</label>
                    <HotelDatePicker
                      @check-in-changed="setCheckinDate"
                      @check-out-changed="setCheckoutDate" />
                  </div>
                  <div class="form-group travelersSelect">
                    <label>Travelers:</label>
                    <v-select name="travelers"
                              v-model="bookingParams.travelers"
                              v-validate="'required'"
                              :options="travelerOptions"
                              @input="travelersChanged"
                              placeholder="Travelers" />
                  </div>
                  <span class="text-danger small mb-3 d-block fix-margin"
                        v-if="errors.has('travelers')">{{ errors.first('travelers') }}</span>
                  <span class="text-danger small mb-3 d-block fix-margin"
                        v-if="error">{{ error }}</span>
                  <button type="button"
                          v-if="modificationCode"
                          class="btn btn-primary btn-block btn-lg"
                          :disabled="isDisabled"
                          @click="sendRequest('free')">
                    change
                  </button>
                  <button type="button"
                          v-else
                          class="btn btn-primary btn-block btn-lg"
                          :disabled="isDisabled"
                          @click="validateBookingRequest">
                    change
                  </button>
                </form>
              </div>
            </div>
          </section>
          <aside class="col-lg-4 col-md-5 col-sm-6"
                 v-if="getBooking && getBooking.property">
            <ul class="article-to-modify">
              <li>
                <img :src="getBooking.property.images[0].thumbnail"
                     :alt="getBooking.property.name"
                     class="thumb">
                <div class="info">
                  <h2>{{ getBooking.property.name }}</h2>
                  <address class="location">
                    {{ getBooking.property.state.name }}, {{ getBooking.property.country.name }}
                  </address>
                  <hr>
                  <label class="calenBook">
                    <i class="icon-calendar" />
                    <div><small>Check In</small> {{ getBooking.check_in|beatyDate }}</div>
                    <i class="icon-arrow-right" />
                    <div><small>Check Out</small> {{ getBooking.check_out|beatyDate }}</div>
                  </label>
                  <label class="calenBook">
                    <i class="icon-users" />
                    <div><small>Travelers</small> {{ getBooking.travelers }}</div>
                  </label>
                  <hr>
                  <div class="total">
                    Original Total <strong>${{ getBooking.amount }}</strong>
                  </div>
                  <div class="final-total"
                       v-if="getBooking.new_amount">
                    New Total <strong>${{ getBooking.new_amount }}</strong>
                  </div>
                  <div class="total"
                       v-if="getBooking.total_amount">
                    Total Payment <strong>${{ getBooking.total_amount }}</strong>
                  </div>
                </div>
              </li>
            </ul>
          </aside>
          <form v-if="paymentVerification"
                name="f1"
                id="f1"
                action="#"
                method="post"
                class="alignet-form-vpos2 d-none">
            <table>
              <tr>
                <td>acquirerId</td><td>
                  <input type="text"
                         name="acquirerId"
                         v-model="paymentVerification.acquirerId">
                </td>
              </tr>
              <tr>
                <td>idCommerce</td><td>
                  <input type="text"
                         name="idCommerce"
                         v-model="paymentVerification.idCommerce">
                </td>
              </tr>
              <tr>
                <td>purchaseOperationNumber </td><td>
                  <input type="text"
                         name="purchaseOperationNumber"
                         v-model="paymentVerification.purchaseOperationNumber">
                </td>
              </tr>
              <tr>
                <td>purchaseAmount </td><td>
                  <input type="text"
                         name="purchaseAmount"
                         v-model="paymentVerification.purchaseAmount">
                </td>
              </tr>
              <tr>
                <td>purchaseCurrencyCode </td><td>
                  <input type="text"
                         name="purchaseCurrencyCode"
                         v-model="paymentVerification.purchaseCurrencyCode">
                </td>
              </tr>
              <tr>
                <td>purchaseVerification </td><td>
                  <input type="text"
                         name="purchaseVerification"
                         v-model="paymentVerification.purchaseVerification">
                </td>
              </tr>
              <tr>
                <td>language </td><td>
                  <input type="text"
                         name="language"
                         value="EN">
                </td>
              </tr>
              <tr>
                <td>shippingFirstName </td><td>
                  <input type="text"
                         name="shippingFirstName"
                         :value="getUser.name">
                </td>
              </tr>
              <tr>
                <td>shippingLastName </td><td>
                  <input type="text"
                         name="shippingLastName"
                         :value="getUser.surname">
                </td>
              </tr>
              <tr>
                <td>shippingEmail </td><td>
                  <input type="text"
                         name="shippingEmail"
                         :value="getUser.email">
                </td>
              </tr>
              <tr>
                <td>shippingAddress </td><td>
                  <input type="text"
                         name="shippingAddress"
                         value="Direccion ABC">
                </td>
              </tr>
              <tr>
                <td>shippingZIP </td><td>
                  <input type="text"
                         name="shippingZIP"
                         value="ZIP 123">
                </td>
              </tr>
              <tr>
                <td>shippingCity </td><td>
                  <input type="text"
                         name="shippingCity"
                         value="CITY ABC">
                </td>
              </tr>
              <tr>
                <td>shippingState </td><td>
                  <input type="text"
                         name="shippingState"
                         value="STATE ABC">
                </td>
              </tr>
              <tr>
                <td>shippingCountry </td><td>
                  <input type="text"
                         name="shippingCountry"
                         value="PE">
                </td>
              </tr>
              <!--Parametro para la Integracion con Pay-me. Contiene el valor del parametro codCardHolderCommerce.-->
              <tr>
                <td>userCommerce </td><td>
                  <input type="text"
                         name="userCommerce"
                         value="modalprueba1">
                </td>
              </tr> <!-- 0101010101 -->
              <!--Parametro para la Integracion con Pay-me. Contiene el valor del parametro codAsoCardHolderWallet.-->
              <tr>
                <td>userCodePayme </td><td>
                  <input type="text"
                         name="userCodePayme"
                         value="8--580--4390">
                </td>
              </tr> <!-- 5--420--2340 -->
              <tr>
                <td>descriptionProducts </td><td>
                  <input type="text"
                         name="descriptionProducts"
                         value="HBL Booking">
                </td>
              </tr>
              <tr>
                <td>programmingLanguage </td><td>
                  <input type="text"
                         name="programmingLanguage"
                         value="PHP">
                </td>
              </tr>
              <!--Ejemplo envío campos reservados en parametro reserved1.-->
              <tr>
                <td>reserved1 </td><td>
                  <input type="text"
                         name="reserved1"
                         value="EN">
                </td>
              </tr>
              <tr v-if="paymentVerification && paymentVerification.reserved2">
                <td>reserved2 </td><td>
                  <input type="text"
                         name="reserved2"
                         v-model="paymentVerification.reserved2">
                </td>
              </tr>
              <tr v-if="paymentVerification && paymentVerification.reserved3">
                <td>reserved3 </td><td>
                  <input type="text"
                         name="reserved3"
                         v-model="paymentVerification.reserved3">
                </td>
              </tr>
              <tr v-if="paymentVerification && paymentVerification.reserved4">
                <td>reserved4 </td><td>
                  <input type="text"
                         name="reserved4"
                         v-model="paymentVerification.reserved4">
                </td>
              </tr>
              <tr v-if="paymentVerification && paymentVerification.reserved6">
                <td>reserved6 </td><td>
                  <input type="text"
                         name="reserved6"
                         v-model="paymentVerification.reserved6">
                </td>
              </tr>
              <tr v-if="paymentVerification && paymentVerification.reserved7">
                <td>reserved7 </td><td>
                  <input type="text"
                         name="reserved7"
                         v-model="paymentVerification.reserved7">
                </td>
              </tr>
            </table>
          </form>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script>
import HotelDatePicker from 'vue-hotel-datepicker';
import vSelect from 'vue-select';
import { mapActions } from 'vuex';
import BookingInfo from '../../components/users/BookingInfo.vue';

export default {
  name: 'UserTripModify',
  components: {
    HotelDatePicker,
    vSelect,
    BookingInfo
  },
  data() {
    return {
      booking: {},
      verificationData: {},
      modificationCode: null,
      user: {},
      bookingParams: {
        travelers: { label: '1 Travelers', value: 1 },
        check_in: '',
        check_out: '',
      },
      error: '',
      isDisabled: true,
      travelerOptions: [
      ]
    };
  },
  methods: {
    ...mapActions([
      'requestBookingTrip',
    ]),
    validateBookingRequest() {
      AlignetVPOS2.openModal('https://vpayment.verifika.com/');
    },
    setCheckinDate(checkIn) {
      const month = (`0${checkIn.getMonth() + 1}`).slice(-2);
      this.bookingParams.check_in = `${checkIn.getFullYear()}-${month}-${checkIn.getDate()}`;
    },
    setCheckoutDate(checkOut) {
      if (checkOut) {
        this.sendRequest();
      } else {
        this.isDisabled = true;
      }
      const month = (`0${checkOut.getMonth() + 1}`).slice(-2);
      this.bookingParams.check_out = `${checkOut.getFullYear()}-${month}-${checkOut.getDate()}`;
    },
    travelersChanged() {
      this.sendRequest();
    },
    sendRequest(flag = null) {
      this.error = '';
      this.$validator.validateAll().then((result) => {
        const formData = new FormData();
        formData.append('check_in', this.bookingParams.check_in);
        formData.append('check_out', this.bookingParams.check_out);
        formData.append('travelers', this.bookingParams.travelers.value);
        if (result && flag === 'free') {
          this.isDisabled = true;
          this.$store.commit('setIsLoading', true);
          formData.append('modification_code', this.modificationCode);
          this.$axios.post(`/v2/bookings/${this.$route.params.id}/modify/free`, formData).then(() => {
            this.isDisabled = false;
            this.$store.commit('setIsLoading', false);
            // this.booking = response.data.data.booking;
            this.$router.push({ name: 'user-trip-overview' });
            Promise.resolve();
          }).catch((error) => {
            this.error = error.response.data.error;
          });
        } else {
          this.isDisabled = true;
          this.$axios.post(`/v2/bookings/${this.$route.params.id}/modify`, formData).then((response) => {
            this.isDisabled = false;
            this.modificationCode = response.data.data.paymentVerification.modificationCode;
            this.verificationData = response.data.data.paymentVerification;
            this.booking = response.data.data.booking;
            Promise.resolve();
          }).catch((error) => {
            this.error = error.response.data.error;
          });
        }
      });
    }
  },
  mounted() {
    const alignetsac = document.createElement('script');
    alignetsac.setAttribute('src', 'https://vpayment.verifika.com/VPOS2/js/modalcomercio.js');
    document.head.appendChild(alignetsac);
  },
  computed: {
    getBooking() {
      return this.booking;
    },
    paymentVerification() {
      return this.verificationData;
    },
    getUser() {
      return this.user;
    }
  },
  created() {
    this.user = this.$store.getters.getUser;
    this.requestBookingTrip(this.$route.params.id).then(() => {
      this.booking = this.$store.getters.getBookingTrip;
      this.bookingParams.travelers = { label: `${this.booking.travelers} Travelers`, value: this.booking.travelers };
      for (let i = 0; i < this.booking.property.maximum_capacity; i += 1) {
        this.travelerOptions.push({ label: `${i + 1} Travelers`, value: i + 1 });
      }
    });
    // this.booking = JSON.parse(this.$localStorage.get('bookingTrip'));
  },
};
</script>

<style lang="scss" scoped>

</style>
